
<b-modal
  :id="id"
  scrollable
  size="lg"
  no-fade
  :title="modalTitle"
  hide-footer
  @hide="closeModal()"
  @show="addInitialData()"
>
  <div v-for="(module, index) in modules" :key="module.id" class="row">
    <div class="col-12 mt-4">
      <label>
        <strong>{{ module.name }}</strong>
      </label>
      <p class="p-4 bg-light border border-light">
        <pre class="">{{ getEmbedCode(module) }}</pre>
      </p>
      <b-form-group v-if="moduleName === 'ondemandplayer'" label="Chose embed option">
        <b-form-radio v-model="selectedEmbedMode[index]" :name="`iFrameResizer${index}`" value="iFrameResizer">default embed code for iFrame, size based on video size (incl. iFrameResizer)</b-form-radio>
        <b-form-radio v-model="selectedEmbedMode[index]" :name="`fillMode${index}`" value="fillMode">fill style for iFrame, size set by surrounding element (excl. iFrameResizer)</b-form-radio>
      </b-form-group>
      <div v-if="moduleName === 'liveplayer'" class="form-check">
        <input :id="`addIframeResizer_${index}`" v-model="includeIFrameResizer[index]" type="checkbox" class="form-check-input">
        <label :for="`addIframeResizer_${index}`" class="form-check-label">add iFrame Resizer</label>
      </div>
      <div v-if="isClassNeeded" class="form-check">
        <input :id="`addWidgetClass_${index}`" v-model="includeFilterMeClass[index]" type="checkbox" class="form-check-input">
        <label :for="`addWidgetClass_${index}`" class="form-check-label">enable filter by adding class <code class="bg-light mb-0">meta-widget-filter-me</code></label>
      </div>
      <div v-if="isChannelSlugNeeded" class="form-check">
        <input :id="`addChannelSlug_${index}`" v-model="includeChannelSlug[index]" type="checkbox" class="form-check-input">
        <label :for="`addChannelSlug_${index}`" class="form-check-label">add channel slug placeholder</label>
      </div>
      <div v-if="isCrSlugNeeded" class="form-check">
        <input :id="`addCrSlug_${index}`" v-model="includeCrSlug[index]" type="checkbox" class="form-check-input">
        <label :for="`addCrSlug_${index}`" class="form-check-label">add cr slug placeholder</label>
      </div>
      <button type="button" class="btn btn-primary d-flex ml-auto" @click="copyEmbedCode(module)">Copy Embed Code</button>
    </div>
  </div>
</b-modal>
