
<div class="row">
  <div class="col-12">
    <div class="card">
      <h4 class="card-header">
        Capabilities
      </h4>
      <div class="card-body">
        <div class="capability-settings">
          <div v-if="capabilities.length > 0" class="row">
            <div class="form-group col-7">
              <label>Definition</label>
            </div>
            <div class="form-group col-3">
              <label>Group</label>
            </div>
            <div class="form-group col-1">
              <label>Logged in</label>
            </div>
          </div>
          <div v-else class="row">
            <div class="form-group col-12">
              <label>No Capabilities defined. Click</label>
                <button class="btn btn-link inline-link" @click.prevent="addNewDefaultCapability(true)">here</button>
                <label>to add default capabilities.</label>
            </div>
          </div>
          <div v-for="(capability, index) in capabilities" :key="capability.id">
            <div class="row">
              <div class="form-group col-7">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="`capabilities ${index+1}`"
                  rules="required|capabilityJson"
                  tag="div"
                >
                  <AceEditor
                    v-model="capability.capability"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </div>
              <div class="form-group col-3">
                <input v-model.trim="capability.capability_group" type="text" name="capability_group" class="form-control" v-on:input="capability.logged_in = 1">
              </div>
              <div class="col-1 form-group">
                <div class="form-check">
                  <input :id="capability.id + '_logged_in'" v-model="capability.logged_in" class="form-check-input" type="checkbox">
                  <label class="form-check-label" :for="capability.id + '_logged_in'"></label>
                </div>
              </div>
              <div class="col-1">
                <button class="btn btn-danger" @click.prevent="capabilities.splice(index,1)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
          <div>
            <button class="btn btn-primary" @click.prevent="addNewDefaultCapability(false)"><i class="fas fa-plus"></i> add capability</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 mt-4">
    <div class="card">
      <h4 class="card-header">
        Access Settings
      </h4>
      <div class="card-body">
        <div class="form-group">
          <label for="authClient">Auth Client:</label>
          <input id="authClient" v-model.lazy.trim="client.data.authClient" type="text" class="form-control" name="authClient">
          <small class="form-text text-muted">The authClient needs to be configured to enable giftcode creation. Typically it is the client name in lower case.</small>
        </div>
      </div>
    </div>
  </div>
</div>
