<template>
  <section class="container-fluid bg-white">
    <Table
      :data="this.projects || []"
      :columns="table.columns"
      :actionButton="table.actionButton"
      :disableSearch="false"
      :search="table.search"
      :actions="table.actions"
      @rowClicked="table.clickHandler"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ProjectsList',
  data () {
    return {
      table: {
        columns: [
          {
            label: 'Preview',
            icon: true,
            iconClass: (value) => value ? 'fas fa-eye-slash' : 'fas fa-eye',
            property: 'preview',
            sort: { enabled: true }
          },
          {
            label: 'Live',
            badge: true,
            badgeClass: (value) => value === 'live' ? 'badge-danger' : 'badge-secondary',
            transform: (item) => item.live ? 'live' : 'not live',
            sort: { enabled: true }
          },
          {
            label: 'Title',
            property: 'title',
            searchable: true,
            sort: { enabled: true }
          },
          {
            label: 'Name',
            property: 'name',
            searchable: true,
            sort: { enabled: true }
          },
          {
            label: 'Date',
            property: 'date',
            searchable: true,
            sort: { enabled: true, order: 'DESC', default: true }
          },
          {
            label: 'AI Features',
            property: 'enableAIFeatures',
            icon: true,
            iconClass: (value) => value ? 'fas fa-check' : '',
            sort: { enabled: true }
          }
        ],
        actions: [
          { icon: 'fas fa-external-link-square-alt', action: this.openInNewTab },
          { icon: 'fas fa-trash-alt', buttonClass: 'btn-danger', action: this.deleteItem }
        ],
        actionButton: {
          icon: 'fa fa-plus',
          label: 'Create new project',
          action: () => this.$router.push({ name: 'ProjectCreate', params: { client: this.$route.params.client } })
        },
        clickHandler: this.editProject,
        search: {
          placeholder: 'Search projects',
          disabled: false
        }
      },
      dcrFrontendHost: import.meta.env.VITE_DCR_FRONTEND_HOST
        .replace('http://', '')
        .replace('https://', '')
    }
  },
  computed: {
    ...mapGetters({
      projects: 'getProjectsList'
    }),
    isLoading: {
      get () {
        return this.$store.getters.getLoadingState
      },
      set (val) {
        this.$store.commit('SET_LOADING_STATE', val)
      }
    },
    isLocalHost () {
      return this.dcrFrontendHost.includes('localhost')
    }
  },
  created () {
    this.$store.commit('SET_PROJECTS_LIST', false)
    this.getProjects()
  },
  methods: {
    openInNewTab (item) {
      const targetUrl = `${this.isLocalHost ? 'http' : 'https'}://${this.$route.params.client}.${this.dcrFrontendHost}/${item.name}`
      window.open(targetUrl, '_blank')
    },
    editProject (item) {
      this.$router.push('/clients/' + this.$route.params.client + '/projects/' + item.id)
    },
    getProjects (updateProjectList = false) { // TODO: please Review
      if (!this.projects || updateProjectList) { // TODO: please Review
        this.isLoading = true
        this.$store
          .dispatch('getProjectsList', this.$route.params.client)
          .then(response => {
            this.isLoading = false
          }).catch(error => {
            this.isLoading = false
            this.error = true
            this.$swal({
              title: 'Error',
              text: error,
              icon: 'error'
            })
          })
      }
    },
    deleteItem (item, listIndex) {
      this
        .$swal({
          title: 'Are you really sure?',
          text: 'The Project "' + item.name + '" will be deleted. This cannot be undone!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          cancelButtonText: 'Cancel'
        })
        .then(
          result => {
            if (result.value) {
              this.isLoading = true
              const data = {
                client: this.$route.params.client,
                item: item
              }
              this.$store
                .dispatch('deleteProject', data)
                .then(response => {
                  // this.projects.splice(listIndex, 1)
                  // this.$store.dispatch('getProjectsList', this.$route.params.client)
                  this.getProjects(true) // TODO: please Review
                  this.$swal({
                    title: 'Success',
                    text: 'Project "' + item.name + '" has been deleted',
                    icon: 'success',
                    showConfirmButton: false,
                    toast: true,
                    position: 'top',
                    timer: 2000
                  })
                  this.isLoading = false
                }).catch(error => {
                  this.isLoading = false
                  this.error = true
                  this.$swal({
                    title: 'Error',
                    text: error,
                    icon: 'error'
                  })
                })
            }
          }
        )
    }
  }
}
</script>
