<template>
  <b-modal
    :id="id"
    scrollable
    size="lg"
    no-fade
    :title="modalTitle"
    hide-footer
    @hide="closeModal()"
    @show="addInitialData()"
  >
    <div v-for="(module, index) in modules" :key="module.id" class="row">
      <div class="col-12 mt-4">
        <label>
          <strong>{{ module.name }}</strong>
        </label>
        <p class="p-4 bg-light border border-light">
          <pre class="">{{ getEmbedCode(module) }}</pre>
        </p>
        <b-form-group v-if="moduleName === 'ondemandplayer'" label="Chose embed option">
          <b-form-radio v-model="selectedEmbedMode[index]" :name="`iFrameResizer${index}`" value="iFrameResizer">default embed code for iFrame, size based on video size (incl. iFrameResizer)</b-form-radio>
          <b-form-radio v-model="selectedEmbedMode[index]" :name="`fillMode${index}`" value="fillMode">fill style for iFrame, size set by surrounding element (excl. iFrameResizer)</b-form-radio>
        </b-form-group>
        <div v-if="moduleName === 'liveplayer'" class="form-check">
          <input :id="`addIframeResizer_${index}`" v-model="includeIFrameResizer[index]" type="checkbox" class="form-check-input">
          <label :for="`addIframeResizer_${index}`" class="form-check-label">add iFrame Resizer</label>
        </div>
        <div v-if="isClassNeeded" class="form-check">
          <input :id="`addWidgetClass_${index}`" v-model="includeFilterMeClass[index]" type="checkbox" class="form-check-input">
          <label :for="`addWidgetClass_${index}`" class="form-check-label">enable filter by adding class <code class="bg-light mb-0">meta-widget-filter-me</code></label>
        </div>
        <div v-if="isChannelSlugNeeded" class="form-check">
          <input :id="`addChannelSlug_${index}`" v-model="includeChannelSlug[index]" type="checkbox" class="form-check-input">
          <label :for="`addChannelSlug_${index}`" class="form-check-label">add channel slug placeholder</label>
        </div>
        <div v-if="isCrSlugNeeded" class="form-check">
          <input :id="`addCrSlug_${index}`" v-model="includeCrSlug[index]" type="checkbox" class="form-check-input">
          <label :for="`addCrSlug_${index}`" class="form-check-label">add cr slug placeholder</label>
        </div>
        <button type="button" class="btn btn-primary d-flex ml-auto" @click="copyEmbedCode(module)">Copy Embed Code</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'CopyEmbedCodeModal',
  props: {
    id: {
      type: String
    },
    module: {
      type: Object,
      required: true
    },
    moduleType: {
      type: String,
      required: true,
      default: ''
    },
    projectName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      selectedEmbedMode: [],
      includeIFrameResizer: [],
      includeFilterMeClass: [],
      includeChannelSlug: [],
      includeCrSlug: [],
      dcrFrontendHost: import.meta.env.VITE_DCR_FRONTEND_HOST
    }
  },
  computed: {
    moduleName () {
      return this.module?.name ? this.module.name : 'liveplayer'
    },
    iframeResizerArray () {
      if (!this.module || !this.module.pivot) {
        return []
      }
      const modulePivotData = JSON.parse(this.module.pivot.data)
      const moduleLength = modulePivotData['collapse-items'] ? modulePivotData['collapse-items'].length : 1
      return Array(moduleLength).fill(true)
    },
    protocolString () {
      const hostString = this.dcrFrontendHost.split(':')[0].toLowerCase()
      return hostString === 'localhost' || hostString === '127.0.0.1' ? 'http' : 'https'
    },
    isCrSlugNeeded () {
      const moduleTypes = ['ondemandplayer']
      if (moduleTypes.includes(this.moduleType)) return true
      return false
    },
    isChannelSlugNeeded () {
      const moduleTypes = ['liveplayer', 'liveeventprogress', 'channelmatrix',
        'livetext', 'overflowplayer', 'resources']
      if (moduleTypes.includes(this.moduleType)) return true
      return false
    },
    isClassNeeded () {
      const moduleTypes = ['webcastdisplay', 'resources', 'filtermatrix']
      if (moduleTypes.includes(this.moduleType)) return true
      return false
    },
    modules () {
      if (!this.module || !this.module.pivot) {
        return []
      }

      const modulePivotData = JSON.parse(this.module.pivot.data)

      if (modulePivotData['collapse-items']) {
        return modulePivotData['collapse-items']
      }
      return [modulePivotData]
    },
    modalTitle () {
      return this.modules.length > 1 ? 'Embed Codes' : 'Embed Code'
    }
  },
  updated () {
    this.includeIFrameResizer = this.iframeResizerArray
  },
  methods: {
    addInitialData () {
      this.selectedEmbedMode = this.modules.map(module => 'iFrameResizer')
    },
    getCrSlug (shouldIncludeCrSlug, embedMode) {
      if (shouldIncludeCrSlug) {
        return embedMode === 'fillMode' ? '&cr=###cr-slug###' : '?cr=###cr-slug###'
      } else {
        return ''
      }
    },
    getEmbedCode (module) {
      const index = this.modules.indexOf(module)
      const channelSlug = this.includeChannelSlug[index] ? '?channel=###channel-slug###' : ''
      const moduleUrl = `${this.getModuleUrl(module)}${channelSlug}`
      const widgetClass = `meta-widget-${this.moduleType}${module.name && module.name !== 'default' ? '-' + module.name : ''}`
      const filterMeClass = `${this.includeFilterMeClass[index] ? ' meta-widget-filter-me' : ''}`
      // configure iframe styling based on embed mode
      let iframeStyles = 'width: 100%;'
      if (this.selectedEmbedMode[index] === 'fillMode') {
        iframeStyles = 'position: absolute; inset: 0; width: 100%; height: 100%;'
      }

      let embedCode = 'something unexpected happened, please contact the support'
      if (this.selectedEmbedMode[index] === 'fillMode') {
        embedCode =
        `<!-- this is your iframe embed code with example wrapper <div> of which the iframe takes the maximum available space -->
<div style="position: relative; width: min(560px, 100%); aspect-ratio: 16/9;">
  <iframe
    src="${moduleUrl}?embedmode=fill${this.getCrSlug(this.includeCrSlug[index], this.selectedEmbedMode[index])}"
    style="${iframeStyles}"
    frameborder="0"
    scrolling="no"
    allow="autoplay"
    allowfullscreen
  ></iframe>
</div>`
      } else {
        const iframeResizerText = `<!-- load the iFrame Resizer script in the <head> of your page -->
<script src="${this.protocolString}://${this.$route.params.client}.${this.dcrFrontendHost}/static/iframe-resizer/iframeResizer.min.js"></\u0073cript>`

        const iframeResizer = this.includeIFrameResizer[index] ? iframeResizerText : ''
        // needs to be indented weirdly to be displayed correctly:
        embedCode = `${iframeResizer}
<!-- this is your iframe embed code -->
<iframe
  src="${moduleUrl}${this.getCrSlug(this.includeCrSlug[index], this.selectedEmbedMode[index])}"
  class="${widgetClass}${filterMeClass}"
  style="${iframeStyles}"
  frameborder="0"
  scrolling="no"
  allow="autoplay"
  allowfullscreen
  onload="iFrameResize({checkOrigin:false},'.${widgetClass}');"
></iframe>`
      }

      return embedCode
    },
    getModuleUrl (module) {
      const moduleName = module.name !== 'default' ? module.name : ''
      return `${this.protocolString}://${this.$route.params.client}.${this.dcrFrontendHost}/${this.projectName}/widgets/${this.moduleType}/${moduleName}`
    },
    async copyEmbedCode (module) {
      await navigator.clipboard.writeText(this.getEmbedCode(module))
      this.$swal({
        title: 'Copied!',
        text: 'Embed code copied to clipboard',
        icon: 'success',
        showConfirmButton: false,
        toast: true,
        position: 'top',
        timer: 2000
      })
    },
    closeModal () {
      this.includeIFrameResizer = []
      this.includeFilterMeClass = []
      this.includeChannelSlug = []
      this.includeCrSlug = []
      this.$emit('close')
    }
  }
}
</script>
