import axios from 'axios'
import qs from 'qs'

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL
axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, { arrayFormat: 'indices' })
}

let isLoadingClient
let clientLoadingPromise

const actions = {
  /*
   * clients related actions
   */
  getClientData (context, client) {
    if (isLoadingClient) {
      return clientLoadingPromise
    }

    const emptyClient = {
      intro: {
        asset: {}
      },
      data: {
        title: '',
        name: '',
        id: undefined,
        description: '',
        apiClient: '',
        apiClientSecret: '',
        apiBaseUrl: import.meta.env.VITE_WCM_BASE_URL,
        apiTokenBaseUrl: import.meta.env.VITE_WCM_TOKEN_BASE_URL,
        apiVersion: 'v2',
        cacheTtl: '10',
        token: '',
        default_styles: '',
        custom_styles: '',
        theme_color: ''
      },
      assets: [],
      security: {
        passcode: false,
        tokenauth: false
      },
      languages: []
    }

    context.commit('SET_CLIENT', emptyClient)

    const url = context.state.serverURI + '/api/v2/clients/name/' + client

    isLoadingClient = true

    const loadingPromise = new Promise((resolve, reject) => {
      return axios
        .get(url)
        .then((response) => {
          context.commit('SET_CLIENT', response.data)
          isLoadingClient = false
          resolve()
        })
        .catch(error => {
          isLoadingClient = false
          console.warn('get client data ', error)
          reject(error)
        })
    })

    clientLoadingPromise = loadingPromise

    return clientLoadingPromise
  },
  getClientsList (context, params) {
    const url = context.state.serverURI + '/api/v2/clients'
    return axios
      .get(url)
      .then(response => {
        context.commit('SET_CLIENTS_LIST', response.data)
        return response.data
      })
      .catch(e => {
        console.warn('get clients ', e)
      })
  },
  createClient (context, data) {
    const url = context.state.serverURI + '/api/v2/clients'
    return axios
      .post(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('create client ', e)
      })
  },
  updateClient (context, { clientId, data }) {
    const url = context.state.serverURI + '/api/v2/clients/' + clientId
    return axios
      .put(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('update client ', e)
      })
  },

  /*
   * groups related actions
   */
  getGroupsList (context, client) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + client + '/groups'
    axios
      .get(url)
      .then(response => {
        context.commit('SET_GROUPS_LIST', response.data)
      })
      .catch(e => {
        console.warn('get groups', e)
      })
  },
  getGroup (context, groupId) {
    const url = context.state.serverURI + '/api/v2/groups/' + groupId
    return axios
      .get(url)
      .then(response => {
        context.commit('SET_GROUP_NAME', response.data.name)
        return response.data
      })
      .catch(e => {
        console.warn('get group', e)
      })
  },
  createGroup (context, { client, data }) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + client + '/groups'
    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  updateGroup (context, { groupId, data }) {
    const url = context.state.serverURI + '/api/v2/groups/' + groupId
    return axios
      .put(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('update group ', e)
      })
  },
  deleteGroup (context, data) {
    const url = context.state.serverURI + '/api/v2/groups/' + data.item.id
    return axios
      .delete(url)
      .then(response => {})
      .catch(e => {
        console.warn('delete group', e)
      })
  },

  /*
   * projects related actions
   */
  getProjectsList (context, client) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + client + '/projects'
    axios
      .get(url)
      .then(response => {
        context.commit('SET_PROJECTS_LIST', response.data)
      })
      .catch(e => {
        console.warn('get projects', e)
      })
  },
  getProjectsListForClient (context, client) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + client + '/projects'
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get projects', e)
      })
  },
  getProject (context, projectId) {
    const url =
      context.state.serverURI + '/api/v2/projects/' + projectId + '/edit'
    return axios
      .get(url)
      .then(response => {
        context.commit('SET_PROJECT_NAME', response.data.name)
        return response.data
      })
      .catch(e => {
        console.warn('get project', e)
      })
  },
  createProject (context, { client, data }) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + client + '/projects'
    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  updateProject (context, { projectId, data }) {
    const url = context.state.serverURI + '/api/v2/projects/' + projectId
    return axios
      .put(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('update project ', e)
      })
  },
  deleteProject (context, data) {
    const url = context.state.serverURI + '/api/v2/projects/' + data.item.id
    return axios
      .delete(url)
      .then(response => {})
      .catch(e => {
        console.warn('delete project', e)
      })
  },
  getProjectAvailableSections (context, projectId) {
    const url =
      context.state.serverURI +
      '/api/v2/projects/' +
      projectId +
      '/available-sections'
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  getPartialProjectData (context, { projectId, tab, type = null }) {
    const url =
      context.state.serverURI + '/api/v2/projects/' + projectId + '/' + tab
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  updatePartialProjectData (context, { projectId, data, tab, type = null }) {
    const url =
      context.state.serverURI + '/api/v2/projects/' + projectId + '/' + tab
    return new Promise((resolve, reject) => {
      axios
        .put(url, data)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },

  /*
   * packages related actions
   */
  getPackagesList (context, client) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + client + '/packages'
    axios
      .get(url)
      .then(response => {
        context.commit('SET_PACKAGES_LIST', response.data)
      })
      .catch(e => {
        console.warn('get packages failed: ', e)
      })
  },
  getPackage (context, packageId) {
    const url =
      context.state.serverURI + '/api/v2/packages/' + packageId + '/edit'
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get package failed: ', e)
      })
  },
  createPackage (context, { client, data }) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + client + '/packages'
    return axios
      .post(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('create package failed: ', e)
      })
  },
  updatePackage (context, { packageId = false, data }) {
    const url = context.state.serverURI + '/api/v2/packages/' + packageId
    return new Promise((resolve, reject) => {
      axios
        .put(url, data)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  deletePackage (context, data) {
    const url = context.state.serverURI + '/api/v2/packages/' + data.item.id
    return axios
      .delete(url)
      .then(response => {})
      .catch(e => {
        console.warn('delete package failed: ', e)
        return Promise.reject(e)
      })
  },

  /*
   * spotlights related actions
   */
  getSpotlightsList (context, client) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + client + '/spotlights'
    axios
      .get(url)
      .then(response => {
        context.commit('SET_SPOTLIGHTS_LIST', response.data)
      })
      .catch(e => {
        console.warn('get spotlights failed: ', e)
      })
  },
  getSpotlight (context, spotlightId) {
    const url =
      context.state.serverURI + '/api/v2/spotlights/' + spotlightId + '/edit'
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get spotlight failed: ', e)
      })
  },
  createSpotlight (context, { client, data }) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + client + '/spotlights'
    return axios
      .post(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('create spotlight failed: ', e)
      })
  },
  updateSpotlight (context, { spotlightId = false, data }) {
    const url = context.state.serverURI + '/api/v2/spotlights/' + spotlightId
    return new Promise((resolve, reject) => {
      axios
        .put(url, data)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },

  deleteSpotlight (context, data) {
    const url = context.state.serverURI + '/api/v2/spotlights/' + data.item.id
    return axios
      .delete(url)
      .then(response => {})
      .catch(e => {
        console.warn('delete spotlight failed: ', e)
        return Promise.reject(e)
      })
  },

  /*
   * educationitems related actions
   */
  getEducationitemsList (context, client) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + client + '/educationitems'
    axios
      .get(url)
      .then(response => {
        context.commit('SET_EDUCATIONITEMS_LIST', response.data)
      })
      .catch(e => {
        console.warn('get educationitems', e)
      })
  },
  getEducationitem (context, educationitemId) {
    const url =
      context.state.serverURI +
      '/api/v2/educationitems/' +
      educationitemId +
      '/edit'
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get educationitem', e)
      })
  },
  createEducationitem (context, { client, data }) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + client + '/educationitems'
    return axios
      .post(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('delete educationitem', e)
      })
  },
  updateEducationitem (context, { educationitemId = false, data }) {
    const url =
      context.state.serverURI + '/api/v2/educationitems/' + educationitemId
    return axios
      .put(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('save educationitem', e)
      })
  },
  deleteEducationitem (context, data) {
    const url =
      context.state.serverURI + '/api/v2/educationitems/' + data.item.id
    return axios
      .delete(url)
      .then(response => {})
      .catch(e => {
        console.warn('delete educationitem', e)
      })
  },

  /*
   * pages related actions
   */
  getPages (context, { clientId, projectId, groupId, types, slug }) {
    const url = context.state.serverURI + '/api/v2/pages'

    const params = {
      clientId,
      projectId,
      groupId,
      type: types.join(','),
      slug
    }

    return axios
      .get(url, { params: params })
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get pages', e)
      })
  },
  getPage (context, pageId) {
    const url = context.state.serverURI + '/api/v2/pages/' + pageId + '/edit'
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get page', e)
      })
  },

  createPage (context, { relation, relId, type, slug, title, pagewidgets = [] }) {
    if (!['client', 'project', 'group'].includes(relation)) {
      throw new Error(`Unknown relation for page: ${relation}`)
    }

    const url = context.state.serverURI + '/api/v2/pages'
    const data = {
      slug,
      title,
      name: title,
      type,
      pagewidgets,
      [`${relation}_id`]: relId
    }

    return axios
      .post(url, data)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('create page', e)
      })
  },
  updatePage (context, { pageId, data }) {
    const url = context.state.serverURI + '/api/v2/pages/' + pageId
    return axios
      .put(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('update page', e)
      })
  },
  deletePage (context, data) {
    const url = context.state.serverURI + '/api/v2/pages/' + data.pageId
    return axios
      .delete(url)
      .then(response => {})
      .catch(e => {
        console.warn('delete page', e)
      })
  },

  /*
   * pagetemplates related actions
   */
  async getPageTemplates (context, { clientId }) {
    const url = context.state.serverURI + '/api/v2/pages'

    let clientTemplates = []

    if (clientId) {
      clientTemplates = (await axios.get(url, { params: { clientId, type: 'clientTemplate' } })).data
    }

    const adminTemplates = (await axios.get(url, { params: { type: 'adminTemplate' } })).data

    return [...clientTemplates, ...adminTemplates]
  },

  createPageTemplate (context, { pageTemplate, clientId }) {
    const url = context.state.serverURI + '/api/v2/pages'
    const data = {
      name: pageTemplate.name,
      title: pageTemplate.name,
      type: pageTemplate.type,
      client_id: clientId,
      pagewidgets: pageTemplate.pagewidgets
    }

    return axios
      .post(url, data)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('create pagetemplate', e)
      })
  },

  copyPageTemplate (context, pageTemplate) {
    const url = context.state.serverURI + '/api/v2/pages'
    const data = {
      name: pageTemplate.name,
      title: pageTemplate.title || pageTemplate.name,
      type: pageTemplate.type,
      client_id: pageTemplate.client_id,
      pagewidgets: pageTemplate.pagewidgets
    }

    return axios
      .post(url, data)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('copy pagetemplate', e)
      })
  },
  /*
   * librarytypes related actions
   */
  getLibrarytypes (context) {
    const url = context.state.serverURI + '/api/v2/librarytypes'
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get librarytypes', e)
      })
  },
  /*
   * filtertemplates related actions
   */
  getFiltertemplatesList (context, client) {
    const url =
      context.state.serverURI +
      '/api/v2/clients/' +
      client +
      '/filtertemplates'
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get filtertemplates', e)
      })
  },
  getFiltertemplate (context, filtertemplateId) {
    const url =
      context.state.serverURI +
      '/api/v2/filtertemplates/' +
      filtertemplateId +
      '/edit'
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get filtertemplate', e)
      })
  },
  createFiltertemplate (context, { client, data }) {
    const url =
      context.state.serverURI +
      '/api/v2/clients/' +
      client +
      '/filtertemplates'
    return axios
      .post(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('delete filtertemplate', e)
      })
  },
  updateFiltertemplate (context, { filtertemplateId, data }) {
    const url =
      context.state.serverURI + '/api/v2/filtertemplates/' + filtertemplateId
    return axios
      .put(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('update filtertemplate', e)
      })
  },
  deleteFiltertemplate (context, { filtertemplateId }) {
    const url =
      context.state.serverURI + '/api/v2/filtertemplates/' + filtertemplateId
    return axios
      .delete(url)
      .then(response => {})
      .catch(e => {
        console.warn('delete filtertemplate', e)
      })
  },

  /*
   * giftcodes related actions
   */
  getClientGiftcodes (context, clientId) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + clientId + '/giftcodes'
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get giftcodes', e)
      })
  },
  saveClientGiftcodes (context, { clientId, giftcodes }) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + clientId + '/giftcodes'
    return new Promise((resolve, reject) => {
      axios
        .post(url, { data: giftcodes })
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },

  /*
   * advertisements related actions
   */
  getAdvertisementsList (context, client) {
    const url =
      context.state.serverURI +
      '/api/v2/clients/' +
      client +
      '/ee1d4e7c_7e69_4f22_bf44'
    axios
      .get(url)
      .then(response => {
        context.commit('SET_ADVERTISEMENTS_LIST', response.data)
      })
      .catch(e => {
        console.warn('get advertisements failed: ', e)
      })
  },
  getAdvertisement (context, advertisementId) {
    const url =
      context.state.serverURI +
      '/api/v2/ee1d4e7c_7e69_4f22_bf44/' +
      advertisementId +
      '/edit'
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get advertisement failed: ', e)
      })
  },
  createAdvertisement (context, { client, data }) {
    const url =
      context.state.serverURI +
      '/api/v2/clients/' +
      client +
      '/ee1d4e7c_7e69_4f22_bf44'
    return axios
      .post(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('create advertisement failed: ', e)
      })
  },
  updateAdvertisement (context, { advertisementId = false, data }) {
    const url =
      context.state.serverURI +
      '/api/v2/ee1d4e7c_7e69_4f22_bf44/' +
      advertisementId
    return new Promise((resolve, reject) => {
      axios
        .put(url, data)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  deleteAdvertisement (context, data) {
    const url =
      context.state.serverURI +
      '/api/v2/ee1d4e7c_7e69_4f22_bf44/' +
      data.item.id
    return axios
      .delete(url)
      .then(response => {})
      .catch(e => {
        console.warn('delete advertisement failed: ', e)
        return Promise.reject(e)
      })
  },

  /*
   * misc actions
   */
  getAvailableLanguages (context) {
    const url = context.state.serverURI + '/api/v2/languages'
    axios
      .get(url)
      .then(response => {
        context.commit('SET_AVAILABLE_LANGUAGES', response.data)
        // todo: error handling for dubious dates / browsers #moment.js #isoDate
      })
      .catch(e => {
        console.warn('get available languages', e)
      })
  },
  getTimezones (context) {
    const url = context.state.serverURI + '/api/v2/timezones'
    axios
      .get(url)
      .then(response => {
        context.commit('SET_TIMEZONES', response.data)
        // todo: error handling for dubious dates / browsers #moment.js #isoDate
      })
      .catch(e => {
        console.warn('get timezones', e)
      })
  },
  getDistributions (context, params) {
    const url = context.state.serverURI + '/api/v2/distributions'
    return axios
      .get(url, { params: params })
      .then(response => {
        if (response.data) {
          return response.data
        }
      })
      .catch(e => {
        console.warn('get distributions', e)
      })
  },
  getCollections (context, params) {
    const url = context.state.serverURI + '/api/v2/collections'
    return axios
      .get(url, { params: params })
      .then(response => {
        return response.data
        // todo: error handling for dubious dates / browsers #moment.js #isoDate
      })
      .catch(e => {
        console.warn('get collections', e)
      })
  },
  getChannels (context, params) {
    const url = context.state.serverURI + '/api/v2/channels'
    return axios
      .get(url, { params: params })
      .then(response => {
        return response.data
        // todo: error handling for dubious dates / browsers #moment.js #isoDate
      })
      .catch(e => {
        console.warn('get channels', e)
      })
  },
  // Modules related Actions
  getModules (context) {
    const url = context.state.serverURI + '/api/v2/modules'
    return axios
      .get(url)
      .then(response => {
        return response.data
        // todo: error handling for dubious dates / browsers #moment.js #isoDate
      })
      .catch(e => {
        console.warn('get modules', e)
      })
  },
  getModulesForPackages (context) {
    const url = context.state.serverURI + '/api/v2/modules'
    return axios
      .get(url, { params: { packages: true } })
      .then(response => {
        return response.data
        // todo: error handling for dubious dates / browsers #moment.js #isoDate
      })
      .catch(e => {
        console.warn('get modules for packages', e)
      })
  },
  getModulesForSpotlights (context) {
    const url = context.state.serverURI + '/api/v2/modules'
    return axios
      .get(url, { params: { packages: true } })
      .then(response => {
        return response.data
        // todo: error handling for dubious dates / browsers #moment.js #isoDate
      })
      .catch(e => {
        console.warn('get modules for spotlights', e)
      })
  },
  getModulesForEducationitems (context) {
    const url = context.state.serverURI + '/api/v2/modules'
    return axios
      .get(url, { params: { packages: true } })
      .then(response => {
        return response.data
        // todo: error handling for dubious dates / browsers #moment.js #isoDate
      })
      .catch(e => {
        console.warn('get modules for educationitems', e)
      })
  },
  getModuleTemplate (context, moduleId) {
    const url = context.state.serverURI + '/api/v2/modules/' + moduleId
    return axios
      .get(url)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.warn('get module', e)
      })
  },
  createModuleTemplate (context, data) {
    const url = context.state.serverURI + '/api/v2/modules'
    return axios
      .post(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('create module', e)
      })
  },
  updateModuleTemplate (context, { moduleId, data }) {
    const url = context.state.serverURI + '/api/v2/modules/' + moduleId
    return axios
      .put(url, data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('update module', e)
      })
  },
  deleteModuleTemplate (context, data) {
    const url = context.state.serverURI + '/api/v2/modules/' + data.item.id
    axios
      .delete(url)
      .then(response => {})
      .catch(e => {
        console.warn('delete module', e)
      })
  },
  searchResources (context, { client, params }) {
    const url = context.state.serverURI + '/api/v2/search/' + client
    return axios
      .get(url, { params: params })
      .then(response => {
        return response.data.results
      })
      .catch(e => {
        console.warn('delete module', e)
      })
  },
  // Asset related Actions
  createAssetFolder (context, data) {
    const url =
      context.state.serverURI + '/api/v2/clients/' + data.clientId + '/images'
    return axios
      .post(url, data.data)
      .then(response => {
        return response
      })
      .catch(e => {
        console.warn('create asset folder', e)
      })
  },
  deleteAsset (context, data) {
    const url =
      context.state.serverURI +
      '/api/v2/clients/' +
      data.clientId +
      '/assets/' +
      data.asset_id
    return axios.delete(url).then(response => {
      return response.data
    })
  },
  // IscRuled related Actions
  getIscRulesByProjectId (context, projectId) {
    const url =
      context.state.serverURI + '/api/v2/projects/' + projectId + '/iscrules'
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => {
          resolve(response)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  saveIscRulesByProjectId (context, { projectId, iscRules }) {
    const url =
      context.state.serverURI + '/api/v2/projects/' + projectId + '/iscrules'
    return new Promise((resolve, reject) => {
      axios
        .post(url, { iscRules: iscRules })
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  // Search related Actions
  getIndexStatus (context, projectId) {
    const params = {
      projectId: projectId
    }
    const url =
      context.state.serverURI + '/api/v2/searchoperations/index-status'
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params: params })
        .then(response => {
          return resolve(response)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  createIndex (context, projectId) {
    const data = {
      projectId: projectId
    }
    const url =
      context.state.serverURI + '/api/v2/searchoperations/create-index'
    return axios
      .post(url, { data: data })
      .then(response => {
        return response
      })
  },
  rollbackIndex (context, projectId) {
    const data = {
      projectId: projectId
    }
    const url =
      context.state.serverURI + '/api/v2/searchoperations/rollback-index'
    return axios
      .post(url, { data: data })
      .then(response => {
        return response
      })
      .catch(e => {
        return e
      })
  },
  resetAlias (context, projectId) {
    const data = {
      projectId: projectId
    }
    const url =
      context.state.serverURI + '/api/v2/searchoperations/reset-alias'
    return axios
      .post(url, { data: data })
      .then(response => {
        return response
      })
      .catch(e => {
        return e
      })
  },
  reindexResources (context, projectId) {
    const data = {
      projectId: projectId
    }
    const url =
      context.state.serverURI + '/api/v2/searchoperations/reindex-resources'
    return new Promise((resolve, reject) => {
      axios
        .post(url, { data: data })
        .then(response => {
          resolve(response)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  },
  updateResources (context, { projectId, type, ids }) {
    const data = {
      projectId: projectId,
      type: type,
      ids: ids
    }
    const url =
      context.state.serverURI + '/api/v2/searchoperations/update-resources'
    return new Promise((resolve, reject) => {
      axios
        .post(url, { data: data })
        .then(response => {
          resolve(response)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  reindexSpeaker (context, projectId) {
    const data = {
      projectId: projectId
    }
    const url =
      context.state.serverURI + '/api/v2/searchoperations/reindex-speaker'
    return new Promise((resolve, reject) => {
      axios
        .post(url, { data: data })
        .then(response => {
          resolve(response)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  indexingProcessStatus (context, { projectId, processKey }) {
    const params = {
      projectId: projectId,
      processKey: processKey
    }
    const url =
      context.state.serverURI +
      '/api/v2/searchoperations/reindex-process-status'
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params: params })
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  getDefaultSearchfields (context) {
    const url = context.state.serverURI + '/api/v2/default-searchfields'
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => {
          resolve(response.data)
        })
        .catch(e => {
          reject(e)
        })
    })
  }
}

export default actions
