<template>
  <div v-if="partialProjectData" class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <h3>Widgets</h3>
          <hr>
        </div>
      </div>
    </div>
    <section class="container-fluid bg-white">
      <Table
        :data="partialProjectData.modules"
        :columns="table.columns"
        :actions="table.actions"
        :actionButton="table.actionButton"
        :disableSearch="false"
        :search="table.search"
        :rowsNotClickable="table.rowsNotClickable"
        @rowClicked="table.clickHandler"
      />
    </section>

    <div class="col-12">
      <hr>
      <SaveBar
        @save="updateProjectData(close = false)"
        @saveAndClose="updateProjectData(close = true)"
        @cancel="cancel"
      />
    </div>

    <edit-module-modal
      :id="editModuleModalId"
      :title="'Edit Module Settings for: ' + editModule"
      :module="module"
      :moduletype="editModule || ''"
      @cancelAction="resetModule()"
      @updateAction="saveModule($event)"
    />

    <CreateWidgetModal
      :id="createWidgetModalId"
      :availableWidgets="availableModules"
      @widgetAdded="addModule"
      @close="closeCreateWidgetModal"
    />

    <CopyEmbedCodeModal
      v-if="!isLoading"
      :id="copyEmbedCodeModalId"
      :module="module"
      :moduleType="module.name"
      :moduleUrl="this.getModuleUrl(module)"
      :projectName="project.name"
      @close="closeCopyEmbedCodeModal"
    />
  </div>
</template>

<script>
export default {
  name: 'ProjectModules',
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: true,
      table: {
        columns: [
          {
            label: 'Type',
            badge: true,
            badgeClass: () => 'badge-dark',
            property: 'name',
            searchable: true,
            sort: { enabled: true, default: true, order: 'ASC' }
          },
          {
            label: 'Name',
            transform: (item) => this.getModuleName(item),
            searchable: true,
            sort: { enabled: true, order: 'ASC' }
          }
        ],
        actions: [
          { label: 'settings', icon: 'fas fa-pencil-alt', action: this.showModuleSettings, title: 'open settings' },
          { icon: 'fas fa-code', buttonClass: 'btn-primary', action: this.openCopyEmbedCodeModal, title: 'copy embed code' },
          { icon: 'fas fa-external-link-alt', buttonClass: 'btn-default', action: this.openWidgetPreview, title: 'preview widget in new tab' },
          { icon: 'fas fa-trash-alt', buttonClass: 'btn-danger', action: this.removeModule, title: 'delete widget' }
        ],
        actionButton: {
          icon: 'fa fa-plus',
          label: 'Create Widget',
          action: this.openCreateWidgetModal
        },
        clickHandler: () => undefined,
        rowsNotClickable: true,
        search: {
          placeholder: 'Search Modules',
          disabled: false
        }
      },
      editModuleModalId: this.$uuid.v4(),
      createWidgetModalId: this.$uuid.v4(),
      copyEmbedCodeModalId: this.$uuid.v4(),
      availableModules: [],
      editModule: false,
      module: {
        configuration: [],
        model: {},
        id: '',
        layout: {},
        key: 0
      },
      partialProjectData: false,
      dcrFrontendHost: import.meta.env.VITE_DCR_FRONTEND_HOST
    }
  },
  created () {
    this.getProjectData()
    this.getModules()
  },
  methods: {
    openWidgetPreview (module) {
      window.open(this.getModuleUrl(module), '_blank')
    },
    getModuleName (module) {
      if (!module.pivot) {
        return ''
      }

      const modulePivotData = JSON.parse(module.pivot.data)

      if (modulePivotData['collapse-items']) {
        return modulePivotData['collapse-items']
          .map(item => item.name)
          .join(', ')
      }

      return modulePivotData.name
    },
    getModuleUrl (module) {
      let moduleName = this.getModuleName(module)
      if (moduleName.split(',').length > 1) {
        // use first module only
        moduleName = this.getModuleName(module).split(',')[0].trim()
      }
      if (moduleName === 'default') {
        moduleName = ''
      }
      const moduleType = module.name
      const hostString = this.dcrFrontendHost.split(':')[0].toLowerCase()
      const protocolString = hostString === 'localhost' || hostString === '127.0.0.1' ? 'http' : 'https'
      return `${protocolString}://${this.$route.params.client}.${this.dcrFrontendHost}/${this.project.name}/widgets/${moduleType}/${moduleName}`
    },
    openCreateWidgetModal () {
      this.$bvModal.show(this.createWidgetModalId)
    },
    closeCreateWidgetModal () {
      this.$bvModal.hide(this.createWidgetModalId)
    },
    openEditModuleModal () {
      this.$bvModal.show(this.editModuleModalId)
    },
    closeEditModuleModal () {
      this.$bvModal.hide(this.editModuleModalId)
    },
    openCopyEmbedCodeModal (module, key) {
      this.module = this.partialProjectData.modules[key]
      this.$nextTick(() => {
        this.$bvModal.show(this.copyEmbedCodeModalId)
      })
    },
    closeCopyEmbedCodeModal () {
      this.resetModule()
      this.$bvModal.hide(this.copyEmbedCodeModalId)
    },
    resetModule () {
      this.$set(this, 'module', {})
      this.$set(this, 'editModule', false)
      this.closeEditModuleModal()
    },
    saveModule (moduleData) {
      if (moduleData['collapse-items']) {
        if (!moduleData['collapse-items'][0].name) {
          moduleData['collapse-items'][0].name = 'default'
        }
      } else if (!moduleData.name) {
        moduleData.name = 'default'
      }

      const pivot = {
        project_id: this.partialProjectData.id,
        id: this.module.pivotId,
        data: JSON.stringify(moduleData),
        module_id: this.module.id,
        layout: null
      }
      this.$set(this.partialProjectData.modules[this.module.key], 'pivot', pivot)
      this.module = {}
      this.editModule = false
      this.closeEditModuleModal()
    },
    addModule (module) {
      this.partialProjectData.modules.push(module)

      this.$swal({
        title: 'Success',
        text: `new widget ${module.name} added`,
        icon: 'success',
        showConfirmButton: false,
        toast: true,
        position: 'top',
        timer: 2000
      })

      this.closeCreateWidgetModal()
      const key = this.partialProjectData.modules.indexOf(module)
      this.showModuleSettings(module, key)
    },
    removeModule (module, key) {
      this.cancelModule()
      this.partialProjectData.modules.splice(key, 1)
    },
    cancelModule () {
      this.$set(this, 'module', {})
      this.$set(this, 'editModule', false)
    },
    showModuleSettings (module, key) {
      this.cancelModule()
      this.module.layout = ''
      this.module.key = key
      this.module.id = module.id
      this.module.pivotId = module.pivot ? module.pivot.id : null
      this.$set(this.module, 'configuration', JSON.parse(module.configuration))
      this.$set(this.module, 'model', JSON.parse(module.model))
      if (module.pivot) {
        if (module.pivot.data) {
          this.$set(this.module, 'model', JSON.parse(module.pivot.data))
        }
      }
      this.editModule = module.name
      this.openEditModuleModal()
    },
    getModules () {
      this.isLoading = true
      this.$store
        .dispatch('getModules')
        .then(response => {
          this.availableModules = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    getProjectData () {
      this.isLoading = true
      this.$store
        .dispatch('getPartialProjectData', { projectId: this.$route.params.id, tab: 'edit-modules' })
        .then(response => {
          this.partialProjectData = response
          this.isLoading = false
        }).catch(error => {
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: error,
            icon: 'error'
          })
        })
    },
    cancel () {
      this.$router.push('/clients/' + this.$route.params.client + '/projects')
    },
    updateProjectData (close = false) {
      this.isLoading = true
      this.$store
        .dispatch('updatePartialProjectData', { projectId: this.$route.params.id, data: this.partialProjectData, tab: 'edit-modules' })
        .then(response => {
          this.$swal({
            title: 'Success',
            text: response.message,
            icon: 'success',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
          this.isLoading = false
          if (close) {
            this.cancel()
          }
        }).catch(error => {
          this.updateErrors = error.response.data.errors
          this.isLoading = false
          this.$swal({
            title: 'Error',
            text: 'Could not update project: ' + error.response.data.message,
            icon: 'error',
            showConfirmButton: false,
            toast: true,
            position: 'top',
            timer: 2000
          })
        })
    }
  }
}
</script>
