
<ValidationObserver v-if="partialProjectData" ref="observer" v-slot="{ invalid }" tag="div" class="container-fluid">
  <div class="row">
    <div class="col-12">
      <h3>API</h3>
      <hr>
    </div>
  </div>

  <div class="row">
    <div class="col-12">

      <form action="#" role="form">

        <div class="row">
          <div class="col-12">
            <h4>Webcastmanager Configuration</h4>
          </div>
        </div>

        <div class="form-row align-items-center">
          <ValidationProvider v-slot="{ errors }" vid="apiClient" name="api client" tag="div" class="col-xl col-md-12">
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="apiClientInput">API Client *</label>
              <input id="apiClientInput" v-model="partialProjectData.apiClient" type="text" class="form-control" required="required">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" vid="apiClientSecret" name="api client secret" tag="div" class="col-xl col-md-12">
            <div class="form-group" :class="{'has-error': errors[0]}">
              <label for="apiClientSecret">API Client Secret *</label>
              <input id="apiClientSecret" v-model="partialProjectData.apiClientSecret" type="text" class="form-control" required="required">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <div class="col-auto d-none d-xl-block">
            <b-btn id="apiClient" class="btn btn-info mt-3">
              <i class="fas fa-question-circle"></i>
            </b-btn>
            <b-popover target="apiClient" placement="topleft" effect="fade" :triggers="'hover'">
              <template slot="title">Webcastmanager API Client Credentials</template>
              oAuth2 API Client Credentials for the User Group that is granted access to the Collection to be used for this Project
            </b-popover>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h4>Data Source</h4>
          </div>
        </div>

        <ValidationProvider v-slot="{ errors }" vid="collection" name="collection" tag="div" class="form-row align-items-center">
          <div class="col-xl col-md-12 form-group" :class="{'has-error': errors[0]}">
            <div v-if="collections.length > 0">
              <label for="collection">Collection *</label>
              <select id="collection" v-model="partialProjectData.collection" name="collection" class="form-control" required="required" @change="collectionChangeHandler">
                <option value="" selected>Please select collection</option>
                <option v-for="collection in collections" :key="collection.id" :value="collection.id">{{ collection.title + ' (' + collection.id + ')' }}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
            <div v-else class="col">
              <button class="btn btn-success text-center" @click.prevent="getCollections">find Collections</button>
            </div>
          </div>
        </ValidationProvider>

        <div class="row">
          <div class="col-12">
            <h5>Live Content</h5>
          </div>
        </div>

        <ValidationProvider v-slot="{ errors }" vid="liveDistribution" name="live distribution" tag="div" class="form-row align-items-center">
          <div class="col-xl col-md-12 form-group" :class="{'has-error': errors[0]}">
            <label for="liveDistribution">Distribution</label>
            <div v-if="liveDistributions.length > 0">
              <select id="liveDistribution" v-model="partialProjectData.liveDistribution" name="liveDistribution" class="form-control" @change="liveDistributionChangeHandler">
                <option value="" selected>Please select live distribution</option>
                <option v-for="liveDistribution in liveDistributions" :key="liveDistribution.id" :value="liveDistribution.id">{{ (liveDistribution.shortname || liveDistribution.title) + ' (' + liveDistribution.id + ')' }}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
            <div v-else class="col">
              No live distributions defined for collection.
            </div>
          </div>
        </ValidationProvider>

        <div class="form-row align-items-center">
          <div class="col-xl col-md-12 form-group">
            <label>Channels</label><br>
            <div v-if="channels.length > 0" class="d-flex flex-wrap">
              <button v-for="(channel, index) in channels" :key="index" style="min-width:8em;" class="flex-fill btn mr-1 mb-1" :class="channel.active ? 'btn-success' : 'btn-warning'" @click.prevent="changeChannelStatus(index)">{{ channel.title }}</button>
              <div class="row">
                <div class="col">
                  <button class="btn btn-light" @click.prevent="selectAllChannels">select all channels</button>
                  <button class="btn btn-light" @click.prevent="deselectAllChannels">deselect all channels</button>
                </div>
              </div>
            </div>
            <div v-else class="col">
              No channels found for distribution.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h5>On Demand Content</h5>
          </div>
        </div>

        <ValidationProvider v-slot="{ errors }" vid="ondemandDistribution" name="ondemand distribution" tag="div" class="form-row align-items-center">
          <div class="col-xl col-md-12 form-group" :class="{'has-error': errors[0]}">
            <label for="ondemandDistribution">Distribution</label>
            <div v-if="ondemandDistributions.length > 0">
              <select id="ondemandDistribution" v-model="partialProjectData.ondemandDistribution" name="ondemandDistribution" class="form-control" @change="ondemandDistributionChangeHandler">
                <option value="" selected>Please select ondemand distribution</option>
                <option v-for="ondemandDistribution in ondemandDistributions" :key="ondemandDistribution.id" :value="ondemandDistribution.id">{{ (ondemandDistribution.shortname || ondemandDistribution.title) + ' (' + ondemandDistribution.id + ')' }}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
            <div v-else class="col">
              No ondemand distributions defined for collection.
            </div>
          </div>
        </ValidationProvider>

        <div class="form-row align-items-center">
          <div class="col-xl col-md-12">
            <div class="form-group" :class="{'has-error': !searchindexFilter}">
              <label for="filter">Filter for Congressresources</label>
              <div class="input-group">
                <input id="filter" v-model="searchindex.filterPart1" type="text" class="form-control" name="filterPart1" required="required">
                <div class="input-group-append input-group-prepend">
                  <span class="input-group-text">!</span>
                </div>
                <input v-model="searchindex.filterPart2" type="text" class="form-control" name="filterPart2">
              </div>
              <small v-if="!searchindexFilter" class="text-danger">The filter for congressresources filter field is required.</small>
              <small id="searchindexfilter" class="form-text text-muted">
                {{searchindexFilter}}
              </small>
            </div>
            <!-- speacial handling because ValidationProvider does not supprt multiple input fields from same v-model -->
            <ValidationProvider v-slot="{ errors }" vid="searchindex.filter" name="searchindex filter" slim>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>

          <div class="col-xl col-md-12">
            <div class="form-group">
              <label for="eventfilter">Filter for Events</label>
              <div class="input-group">
                <input id="eventfilter" v-model="searchindex.eventfilterPart1" type="text" class="form-control" name="eventfilterPart1">
                <div class="input-group-append input-group-prepend">
                  <span class="input-group-text">!</span>
                </div>
                <input v-model="searchindex.eventfilterPart2" type="text" class="form-control" name="eventfilterPart2">
              </div>
              <small id="searchindexEventfilter" class="form-text text-muted">
                {{searchindexEventfilter}}
              </small>
            </div>
          </div>
        </div>

        <div class="form-row align-items-center">
          <div class="col-xl col-md-12 form-group">
            <label>Elasticsearch Index Status</label>
            <div class="card" :class="elasicsearchIndexStatusClass">
              <h6 class="card-header">{{partialProjectData.indexname}}</h6>
              <div class="card-body">

                <div class="row">
                  <div v-if="esStatus.indexExists" class="col">
                    <div class="row mb-2 mb-xl-0">
                      <div class="col-12 col-xl-auto" style="width:11em;">Elasticsearch alias:</div>
                      <strong class="col">{{ esStatus.aliasOnVersion }}</strong>
                    </div>

                    <div class="row mb-2">
                      <div class="col-12 col-xl-auto" style="width:11em;">DB expected alias:</div>
                      <strong class="col">{{ esStatus.actualVersion }}</strong>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="row mb-2 mb-xl-0">
                          <div class="col-12 col-xl-auto" style="width:11em;">Number of CRs:</div>
                          <strong class="col">{{ esStatus.counts.congressresources }}</strong>
                        </div>
                        <div class="row">
                          <div class="col-12 col-xl-auto" style="width:11em;">Number of Events:</div>
                          <strong class="col">{{ esStatus.counts.events }}</strong>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div v-else class="col">
                    <h3>No index <br><small>create one, maybe?</small></h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 mt-3 mt-xl-0">
            <div class="row">
              <div class="col">
                <button v-if="!esStatus.indexExists" class="btn btn-danger btn-block" @click.prevent="createIndex"><i class="fas fa-plus"></i> create Index</button>
                <button v-else class="btn btn-success btn-block" disabled="true"><i class="fas fa-check"></i> Index exists</button>
              </div>
            </div>
            <div class="row my-2">
              <div class="col d-flex">
                <button type="button" :disabled="elasicsearchIndexStatus !== 'success' || !esStatus.rollbackPossible" class="mr-1 flex-fill btn btn-secondary" @click.prevent="rollbackIndex"><i class="fas fa-backward"></i> rollback index</button>
                <button :disabled="elasicsearchIndexStatus === 'success' || !esStatus.indexExists" class="ml-1 flex-fill btn btn-secondary" @click.prevent="resetAlias"><i class="fas fa-undo"></i> reset alias</button>
              </div>
            </div>

            <hr>

            <div v-if="reindexingResources" class="progress mb-2" style="height:3em;">
              <div class="progress-bar progress-bar-striped progress-bar-animated w-100" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
               <h6 class="pt-2"><div class="spinner-grow spinner-grow-sm" role="status"></div> reindexing CRs, Events &amp; Speakers</h6>
              </div>
            </div>
            <button v-else :disabled="elasicsearchIndexStatus !== 'success'" class="btn btn-primary btn-block" @click.prevent="startReindexingResources"><i class="fas fa-sync"></i> reindex CRs, Events &amp; Speakers</button>

            <div v-if="reindexingSpeaker" class="btn btn-block progress" style="height: 31px;">
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">reindexing Speakers</div>
            </div>
            <button v-else :disabled="elasicsearchIndexStatus !== 'success'" class="btn btn-primary btn-block" @click.prevent="startReindexingSpeaker"><i class="fas fa-sync"></i> reindex Speakers</button>
          </div>
        </div>

        <div class="row mt-3">
          <div v-if="reindexingLog.length > 0" class="col">
            <div class="card text-white bg-dark">
              <div class="card-body">
                <div v-for="entry in reindexingLog" :key="entry.message+entry.created_at" class="row" :class="entry.log_type !== 'info'? 'bg-danger' : ''">
                  <code class="text-white col my-0">[{{entry.log_type}}] {{entry.created_at}} - {{entry.message}}</code>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row align-items-center">
          <div class="col-xl col-md-12">
            <div class="form-group">
              <label for="updateCrs">Update CRs by id(s)</label>
              <div class="input-group">
                <input id="updateCrs" v-model="update.congressresourcesIds" type="text" class="form-control" name="updateCrs">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="button" @click.prevent="updateResources('congressresources')"><i class="fas fa-sync"></i> update/delete</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto d-none d-xl-block">
            <b-btn id="updateCrsInfo" class="btn btn-info mt-3">
              <i class="fas fa-question-circle"></i>
            </b-btn>
            <b-popover target="updateCrsInfo" placement="topleft" effect="fade" :triggers="'hover'">
              <template slot="title">Update Congressresources</template>
              Update selected Congressresources by id<br>
              <span class="small">comma separated list, e.g. 1001,1002,1003,...</span>
            </b-popover>
          </div>
        </div>

        <div class="form-row align-items-center">
          <div class="col-xl col-md-12">
            <div class="form-group">
              <label for="updateEvents">Update Events by id(s)</label>
              <div class="input-group">
                <input id="updateEvents" v-model="update.eventsIds" type="text" class="form-control" name="updateEvents">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="button" @click.prevent="updateResources('events')"><i class="fas fa-sync"></i> update/delete</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto d-none d-xl-block">
            <b-btn id="updateEventInfo" class="btn btn-info mt-3">
              <i class="fas fa-question-circle"></i>
            </b-btn>
            <b-popover target="updateEventInfo" placement="topleft" effect="fade" :triggers="'hover'">
              <template slot="title">Update Events</template>
              Update selected Events by id<br>
              <span class="small">comma separated list, e.g. 1001,1002,1003,...</span>
            </b-popover>
          </div>
        </div>
      </form>
    </div>
  </div>
  <hr>

  <SaveBar
    :saveDisabled="invalid"
    @save="updateProjectData(close = false)"
    @saveAndClose="updateProjectData(close = true)"
    @cancel="cancel"
  />
</ValidationObserver>
