<template>
  <div class="wrapper">
    <header class="main-header">
      <a href="/" class="logo">
        <!-- mini logo for sidebar mini 40x50 pixels -->
        <span class="logo-mini"><img src="/static/img/logo.png" alt="Logo" class="img-responsive center-block"></span>
        <!-- logo for regular state and mobile devices -->
        <div class="logo-lg">
          <div class="pull-left image"><img src="/static/img/logo.png" alt="Logo" class="img-responsive center-block"></div>
        </div>
      </a>

      <!-- Header Navbar -->
      <nav class="navbar navbar-expand-lg" role="navigation">
        <!-- Sidebar toggle button-->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Navbar Right Menu -->
        <ul class="nav navbar-nav ml-auto">
          <!-- User Account Menu -->

          <li class="nav-item dropdown">
            <button id="dropdownMenuButton" class="btn btn-link text-dark dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i v-if="this.user.is_admin" class="fas fa-user-shield"></i>
              <i v-else class="fas fa-user"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <h6 class="dropdown-header">
                <strong>{{ userDisplayName }}</strong><br>
                {{this.user.email}}
              </h6>
              <button class="dropdown-item" type="button" @click.prevent="editUser(user)">Edit User</button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" type="button" @click.prevent="logout">Sign out</button>
            </div>
          </li>

        </ul>

      </nav>
    </header>

    <!-- Left side column. contains the logo and sidebar -->
    <aside class="main-sidebar">

      <!-- sidebar: style can be found in sidebar.less -->
      <section class="sidebar">

        <!-- Sidebar user panel (optional) -->
        <!-- <div class="user-panel">
          <div class="pull-left info" style="left:10px">
            <div>
              <p class="white">{{ userDisplayName }}</p>
            </div>
            <a href="#" @click.prevent>
              <i class="fa fa-circle text-success"></i> Online
            </a>
          </div>
        </div>
        <br><br> -->

        <!-- Sidebar Menu -->
        <ul class="sidebar-menu" data-widget="tree">
          <li class="header">SETTINGS</li>

          <router-link v-if="$route.params.client" tag="li" class="pageLink" :to="{name: 'ClientsList'}">
            <a><i class="fas fa-arrow-alt-circle-up"></i> <span class="page">All Clients</span></a>
            <ul class="treeview-menu">
              <router-link tag="li" class="pageLink" :to="{name: 'ClientEdit', params: {client: $route.params.client}}">
                <a><i class="fas fa-edit fa-fw"></i> <span class="page">{{ $route.params.client.toUpperCase() }}</span></a>
              </router-link>
              <!-- <router-link tag="li" class="pageLink" :to="'/clients/'+$route.params.client+'/pages'">
                <a><i class="fa fa-sitemap"></i> <span class="page">Pages</span></a>
              </router-link> -->
              <router-link tag="li" class="pageLink" :to="{name: 'GroupsList', params: {client: $route.params.client}}">
                <a><i class="fas fa-clone fa-fw"></i> <span class="page">Groups</span></a>
              </router-link>
              <router-link tag="li" class="pageLink" :to="{name: 'ProjectsList', params: {client: $route.params.client}}">
                <a><i class="fas fa-table fa-fw"></i> <span class="page">Projects</span></a>
              </router-link>
              <router-link tag="li" class="pageLink" :to="{name: 'PackagesList', params: {client: $route.params.client}}">
                <a><i class="fas fa-boxes fa-fw"></i> <span class="page">Packages</span></a>
              </router-link>
              <router-link tag="li" class="pageLink" :to="{name: 'AdvertisementsList', params: {client: $route.params.client}}">
                <a><i class="far fa-handshake fa-fw"></i> <span class="page">Advertisements</span></a>
              </router-link>
              <router-link tag="li" class="pageLink" :to="{name: 'SpotlightsList', params: {client: $route.params.client}}">
                <a><i class="fas fa-industry fa-fw"></i> <span class="page">Spotlights</span></a>
              </router-link>
              <router-link tag="li" class="pageLink" :to="{name: 'EducationitemsList', params: {client: $route.params.client}}">
                <a><i class="fas fa-graduation-cap fa-fw"></i> <span class="page">Education</span></a>
              </router-link>
              <router-link tag="li" class="pageLink" :to="{name:'ClientPageTemplateList', params: {client: $route.params.client}}">
                <a><i class="far fa-file-alt fa-fw"></i> <span class="page">Page Templates</span></a>
              </router-link>
              <router-link tag="li" class="pageLink" :to="{name: 'FiltertemplatesList', params: {client: $route.params.client}}">
                <a><i class="fas fa-filter fa-fw"></i> <span class="page">Filter Templates</span></a>
              </router-link>
              <router-link tag="li" class="pageLink" :to="{name: 'Giftcodes', params: {client: $route.params.client}}">
                <a><i class="fas fa-gift fa-fw"></i> <span class="page">Gift codes</span></a>
              </router-link>
            </ul>
          </router-link>
          <router-link v-else tag="li" class="pageLink" :to="{name: 'ClientsList'}">
            <a><i class="fa fa-table fa-fw"></i> <span class="page">Clients</span></a>
          </router-link>

          <li v-if="user.is_admin" class="header">ADMIN SETTINGS</li>
          <router-link v-if="user.is_admin" tag="li" class="pageLink" :to="{name:'DomainsList'}">
            <a><i class="fas fa-globe fa-fw"></i> <span class="page">Domains</span></a>
          </router-link>
          <router-link v-if="user.is_admin" tag="li" class="pageLink" :to="{name:'PageTemplateList'}">
            <a><i class="far fa-file-alt fa-fw"></i> <span class="page">Page Templates</span></a>
          </router-link>
          <router-link v-if="user.is_admin" tag="li" class="pageLink" :to="{name:'ModuleTemplatesList'}">
            <a><i class="fas fa-cubes fa-fw"></i> <span class="page">Module Templates</span></a>
          </router-link>

          <!--li class="pageLink"><router-link to="/templates"><i class="fa fa-table fa-fw"></i><span class="page">Templates</span></router-link></li-->

          <li v-if="user.is_admin" class="header">USERS</li>
          <router-link v-if="user.is_admin" tag="li" class="pageLink" :to="{name: 'UsersList'}">
            <a><i class="fas fa-user-cog fa-fw"></i> <span class="page">Users</span></a>
          </router-link>
          <router-link v-if="user.is_admin" tag="li" class="pageLink" :to="{name: 'UsergroupsList'}">
            <a><i class="fas fa-users fa-fw"></i> <span class="page">Usergroups</span></a>
          </router-link>
          <router-link v-if="user.is_admin" tag="li" class="pageLink" :to="{name: 'UserrolesList'}">
            <a><i class="fas fa-user-graduate fa-fw"></i> <span class="page">Userroles</span></a>
          </router-link>

          <li class="pageLink">
            <a href="#" @click.prevent="logout">
              <i class="fas fa-sign-out-alt text-red fa-fw"></i> <span class="page">Sign out</span>
            </a>
          </li>
        </ul>
        <!-- /.sidebar-menu -->
      </section>
      <!-- /.sidebar -->
    </aside>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <h1>
          {{ $route.meta.title || $route.name }}
          <small>{{ $route.meta.description }}</small>
        </h1>
        <ol class="breadcrumb nav">
          <li>
            <router-link to="/">
              <i class="fas fa-home"></i> Home
            </router-link>
          </li>
          <li class="active">{{ $route.meta.title || $route.name }}</li>
        </ol>
      </section>

      <transition name="fade">
        <router-view></router-view>
      </transition>

    </div>
    <!-- /.content-wrapper -->

    <!-- Main Footer -->
    <footer class="main-footer">
      &copy; {{year}} <a href="https://www.meta-fusion.com/" target="_blank">meta-fusion GmbH</a>
    </footer>
  </div>
  <!-- ./wrapper -->
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Dash',
  data () {
    return {
      client: 0,
      section: 'Dash',
      error: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    userDisplayName () {
      return this.user.firstname + ' ' + this.user.lastname
    },
    year () {
      var y = new Date()
      return y.getFullYear()
    }
  },
  mounted () {
    // get full user data by email
    if (!this.user.email) {
      this.isLoading = true
      this.$store
        .dispatch('getUserData', { params: { email: this.user } })
        .then(response => {
          this.isLoading = false
        })
    }
  },
  methods: {
    editUser (user) {
      this.$router.push('/users/profile/' + user.id)
    },
    changeloading () {
      this.$store.dispatch('TOGGLE_SEARCHING')
    },
    logout () {
      return this.$parent.logout()
    }
  }
}
</script>
